import React from 'react'

const SvgIcNoticias = props => (
  <svg viewBox="0 0 32 32" width={32} height={32} {...props}>
    <defs>
      <path
        d="M24.961 5C26.65 5 28 6.657 28 8.674V9h2.149C31.18 9 32 9.873 32 10.929v14.693c0 2.356-1.768 4.292-4 4.375V30H3.039C1.35 30 0 28.343 0 26.326V8.674C0 6.657 1.35 5 3.039 5H24.96zm-.03 2H3.07C2.489 7 2 7.62 2 8.41v18.18c0 .79.489 1.41 1.069 1.41H26V8.41C26 7.62 25.511 7 24.931 7zm4.899 4.225h-1.66v16.522c.935-.166 1.66-1.047 1.66-2.125V11.225zM4.67 23h18.66c.38.049.67.482.67 1 0 .475-.244.879-.577.98l-.093.02H4.67c-.38-.049-.67-.482-.67-1 0-.475.244-.879.577-.98L4.67 23h18.66zm0-4h18.66c.38.049.67.482.67 1 0 .475-.244.879-.577.98l-.093.02H4.67c-.38-.049-.67-.482-.67-1 0-.475.244-.879.577-.98L4.67 19h18.66zm8.854-9c.815 0 1.476.686 1.476 1.532v3.936c0 .846-.66 1.532-1.476 1.532H5.476C4.661 17 4 16.314 4 15.468v-3.936C4 10.686 4.66 10 5.476 10h8.048zm3.104 5h6.744c.356.049.628.482.628 1 0 .475-.228.879-.54.98l-.088.02h-6.744c-.356-.049-.628-.482-.628-1 0-.475.228-.879.54-.98l.088-.02h6.744zM13 12H6v3h7v-3zm10.372-1c.356.049.628.482.628 1 0 .475-.228.879-.54.98l-.088.02h-6.744c-.356-.049-.628-.482-.628-1 0-.475.228-.879.54-.98l.088-.02h6.744z"
        id="noticias_svg__a"
      />
    </defs>
    <g fill={props.fill} fillRule="evenodd">
      <mask id="noticias_svg__b" fill="#fff">
        <use xlinkHref="#noticias_svg__a" />
      </mask>
      <use  fillRule="nonzero" xlinkHref="#noticias_svg__a" />
      <g mask="url(#noticias_svg__b)" fill={props.fill} >
        <path d="M0 0h32v32H0z" />
      </g>
    </g>
  </svg>

)

export default SvgIcNoticias
