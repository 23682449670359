import React from 'react'

const SvgIcCorpoClinico = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M10.53 18.298a3.178 3.178 0 012.117 2.996v1.412a1.059 1.059 0 01-2.118 0v-1.412a1.059 1.059 0 00-2.117 0v1.412a1.059 1.059 0 01-2.118 0v-1.412c0-1.383.884-2.56 2.118-2.996v-8.65c0-.023 0-.045.002-.067l-.747-.64a4.588 4.588 0 00-4.55 4.588v13.563a1.059 1.059 0 11-2.117 0V13.53a6.706 6.706 0 016.62-6.705l2.465-2.466c.186-.214.46-.358.797-.358h9.883c.337 0 .611.144.797.358l2.492 2.493c.077-.018.158-.027.24-.027A6.706 6.706 0 0131 13.529v13.563a1.059 1.059 0 11-2.118 0V13.53a4.588 4.588 0 00-4.588-4.588 1.06 1.06 0 01-.273-.035l-1.492 1.278v8.114a3.178 3.178 0 01-1.058 6.173 3.176 3.176 0 01-1.06-6.173V12l-1.075.922c-.42.36-1.046.336-1.438-.056l-1.016-1.016v15.257a1.059 1.059 0 11-2.117 0V11.85l-1.016 1.016a1.059 1.059 0 01-1.438.056l-1.782-1.527v6.903zm10.491-9.61l1.01-.865L20.709 6.5l-3.328 2.853 1.321 1.322 1.89-1.62c.107-.158.255-.286.43-.368zm-6.755.666L10.938 6.5 9.616 7.823l3.329 2.853 1.321-1.322zm-.521-3.236L15.824 7.9l2.078-1.782h-4.157zm7.726 16.235a1.059 1.059 0 100-2.118 1.059 1.059 0 000 2.118z"
        id="ic-corpo-cli\u0301nico_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-corpo-cli\u0301nico_svg__b" fill={props.fill}>
        <use xlinkHref="#ic-corpo-cli\u0301nico_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-corpo-cli\u0301nico_svg__a" />
    </g>
  </svg>
)

export default SvgIcCorpoClinico
