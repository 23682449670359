import SvgIcConvenios from 'atomic/atm.svg-icon/ic-convenios'
import SvgIcCorpoClinico from 'atomic/atm.svg-icon/ic-corpo-clinico'
import SvgIcDoctorRegister from 'atomic/atm.svg-icon/ic-doctor-register'
import SvgIcExames from 'atomic/atm.svg-icon/ic-exames'
import SvgIcInstrucoesResultados from 'atomic/atm.svg-icon/ic-instrucoes-resultados'
import SvgIcManualDeExames from 'atomic/atm.svg-icon/ic-manual-de-exames'
import SvgIcNoticias from 'atomic/atm.svg-icon/ic-noticias'
import SvgIcUnidades from 'atomic/atm.svg-icon/ic-unidades'
import * as React from 'react'
import { QuickAccessItem } from 'utils/model/quick-access'
import { appPaths, externalPaths } from 'utils/path'

export const doctorQuickAccess: QuickAccessItem[] = [
  {
    path: externalPaths.weinmannExamResultsDoctor.path,
    icon: <SvgIcInstrucoesResultados height={24} />,
    title: 'Resultados de exames'
  },
  {
    path: appPaths.examManual.path.doctorUrl,
    icon: <SvgIcManualDeExames height={24} />,
    title: 'Manual de exames'
  },
  {
    path: appPaths.ourSpecialists.path.doctorUrl,
    icon: <SvgIcCorpoClinico height={24} />,
    title: 'Corpo clínico'
  },
  {
    path: appPaths.units.path.doctorUrl,
    icon: <SvgIcUnidades height={24} />,
    title: appPaths.units.name
  },
  {
    path: appPaths.doctorRegister.path.doctorUrl,
    icon: <SvgIcDoctorRegister height={24} />,
    title: appPaths.doctorRegister.name
  }
]

export const clientQuickAccess: QuickAccessItem[] = [
  {
    path: externalPaths.weinmannExamResultsClient.path,
    icon: <SvgIcInstrucoesResultados height={24} />,
    title: 'Resultados de exames'
  },
  {
    path: appPaths.exams.path.clientUrl,
    icon: <SvgIcExames height={24} />,
    title: 'Exames e vacinas'
  },
  {
    path: appPaths.convenio.path.clientUrl,
    icon: <SvgIcConvenios height={24} />,
    title: 'Convênios atendidos'
  },
  {
    path: appPaths.units.path.clientUrl,
    icon: <SvgIcUnidades height={24} />,
    title: 'Nossas unidades'
  },
  {
    path: appPaths.news.path.clientUrl,
    icon: <SvgIcNoticias height={24} />,
    title: appPaths.news.name
  }
]
